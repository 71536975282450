@import "~@/styles/themes.scss";
.inputLabel {
  font-weight: 600;
  font-family: $secondary-font;
  margin-bottom: 5px;
}

.flextitle {
  display: flex;
}

.mobileInputAdmin {
  .required-field::after {
    content: '*';
    color: red;
  }
  .flextitle {
    display: flex;
    .label {
      // font-weight: 600;
      font-family: $secondary-font;
      margin-bottom: 5px;
      font-size: 14px;
      &:after {
        content: '*';
        color: red;
      }
    }
  }

  .mobileInputContainer {
    display: flex;
    width: 100%;
    // height: 60px;
    font-size: 14px;
    margin-bottom: 20px;

    .leftSide {
      border-radius: 10px 0px 0px 10px;
      // font-size: 14px;
      // height: 60px !important;
      width: 200px;
      font-size: 14px;
    }
    .selectDropdown {
      background-color: orange;
    }
    :global {
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%;
        height: 40px !important;
        padding: 0 11px;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        position: relative;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 10px 0px 0px 10px;
        -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        .ant-select-selection-search {
          display: flex;
          align-items: center;
        }
      }
      .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
      .ant-select-single.ant-select-show-arrow
        .ant-select-selection-placeholder {
        padding-right: 18px;
        display: flex;
        align-items: center;
      }
    }

    .rightSide {
      border-radius: 0px 10px 10px 0px;
      font-size: 14px !important;
      border-left: 0px;

      :global {
        .ant-input {
          font-size: 14px;
        }
      }
    }
  }
}

.largePrimaryInput {
  border-radius: 10px;
  height: 60px;
  font-size: 18px;
  width: 100%;
  &:focus {
    border: 2px solid $admin-secondary-color !important;
    box-shadow: none;
    border-color: $admin-secondary-color;
  }

  &:hover {
    box-shadow: none;
    border: 2px solid $admin-primary-color;
  }
  :global {
    .ant-input {
      font-size: 18px;
    }
  }
}

.largePrimaryInputNumber {
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 60px;
  font-size: 18px;
  width: 100%;
  &:focus {
    border: 2px solid $admin-secondary-color !important;
    box-shadow: none;
    border-color: $admin-secondary-color;
  }

  &:hover {
    box-shadow: none;
    border: 2px solid $admin-primary-color;
  }

  :global {
    .ant-input-number-focused {
      border: 2px solid $admin-secondary-color !important;
      box-shadow: none;
      border-color: $admin-secondary-color;
    }
  }
}

.largePrimarySelect {
  width: 100%;
  font-size: 18px;

  :global {
    .ant-select-selector {
      min-height: 60px !important;
      border-radius: 10px !important;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
    .ant-select-selection-placeholder {
      align-self: center;
    }
  }
}

.selectOptions {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}

.largePrimarySelectCompany {
  width: 100%;
  font-size: 14px;

  :global {
    .ant-select-selector {
      height: 30px !important;
      border-radius: 0px !important;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }
}

.selectOptionsCompany {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.datePicker {
  border-radius: 10px;
  height: 60px;
  font-size: 18px;
  width: 100%;

  :global {
    .ant-picker,
    .ant-picker-focused {
      &:focus {
        border: 2px solid $admin-secondary-color !important;
        box-shadow: none;
        border-color: $admin-secondary-color;
      }

      &:hover {
        box-shadow: none;
        border: 2px solid $admin-primary-color !important;
      }
    }
    .ant-picker-input input {
      font-size: 18px !important;
    }
  }
}

.timePicker {
  border-radius: 10px;
  height: 60px;
  font-size: 18px;
  width: 100%;

  :global {
    .ant-picker-input input {
      font-size: 18px !important;
    }
  }
}

.photoField {
  display: flex;
  flex-flow: column;
  height: 100%;

  .imageTopContainer {
    display: flex;
    justify-content: space-between;

    .uploadBtn {
      color: $admin-accent-blue;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .imageContainer {
    flex-grow: 1;
    height: 172px;
    border-radius: 10px;
    border: 2px solid $admin-grey-5;
    background-size: cover;
    background-position: center;
    background-color: #fff;
  }

  .imageContainer__loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .featureImageContainer {
    flex-grow: 1;
    height: 172px;
    border-radius: 10px;
    border: 2px solid $admin-grey-5;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.largeTextArea {
  border-radius: 10px;
  font-size: 18px;

  &:focus {
    border: 2px solid $admin-secondary-color !important;
    box-shadow: none;
    border-color: $admin-secondary-color;
  }

  &:hover {
    box-shadow: none;
    border: 2px solid $admin-primary-color !important;
  }
}

.uploadGallery {
  :global {
    .ant-upload-list-picture-card .ant-upload-list-item {
      border-radius: 10px !important;
      padding: 0;
    }
    .ant-upload-list-picture-card-container {
      height: 87px !important;
      width: 87px !important;
    }
    .ant-upload-list-item-info {
      border-radius: 10px;
    }
    .ant-upload-list-item-thumbnail img {
      object-fit: cover !important;
    }
    .ant-upload-select.ant-upload-select-picture-card {
      height: 87px !important;
      width: 87px !important;
      border: 2px dashed $admin-grey-3;
      border-radius: 10px;
    }
  }
}

.mediaContainer {
  position: relative;
  .videoGallery,
  .imageGallery {
    width: 87px;
    height: 87px;
    object-fit: cover;
    border-radius: 10px;
  }
  .galleryMask {
    position: absolute;
    height: 87px;
    width: 87px;
    top: 0;
    left: 0;
  }

  &:hover .galleryMask {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.mobileInputContainer {
  display: flex;
  width: 100%;
  // height: 60px;
  font-size: 18px;
  // margin-bottom: 20px;

  .leftSide {
    border-radius: 10px 0px 0px 10px;
    // font-size: 18px;
    // height: 60px !important;
    width: 200px;
    font-size: 18px;
  }
  .selectDropdown {
    background-color: orange;
  }
  :global {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      height: 60px !important;
      padding: 0 11px;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      position: relative;
      background-color: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 10px 0px 0px 10px;
      -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      .ant-select-selection-search {
        display: flex;
        align-items: center;
      }
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      padding-right: 18px;
      display: flex;
      align-items: center;
    }
  }

  .rightSide {
    border-radius: 0px 10px 10px 0px;
    font-size: 18px !important;
    border-left: 0px;

    :global {
      .ant-input {
        font-size: 18px;
      }
    }
  }
}

.custom__email_container {
  display: flex;
  flex-direction: column;
  position: relative;

  .custom__email_action {
    position: absolute;
    right: 0;
    margin-left: 5px;

    :hover {
      cursor: pointer;
    }

    &_blue {
      color: #1890ff;
      font-family: 'Muli';
      font-size: 14px;
      font-weight: 600;
    }
    &_grey {
      color: #8c8c8c;
      font-family: 'Muli';
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.formErrorMessage {
  // same color as antd form validate message
  color: #ff4d4f;
}

.HourlyRateInput {
  .hourlyRateLabel {
    display: flex;
    gap: 15px;
  }

  .hourlyRateValue {
    color: $admin-grey-2;
    font-family: $primary-font;
    font-size: 18px;
    font-weight: 600;
  }

  .roleName {
    color: $admin-grey-3;
  }

  .updateButtons {
    display: flex;
    justify-content: right;
  }

  .valueDisplay {
    margin-top: 10px;
    margin-left: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .blueButton {
    color: $admin-accent-blue;
    font-family: $primary-font;
    font-size: 14px;
    font-weight: 600;

    :hover {
      color: $admin-secondary-color;
    }
    :active {
      color: $admin-grey-1;
    }
  }

  .greyButton {
    color: $admin-grey-3;
    font-family: $primary-font;
    font-size: 14px;
    font-weight: 600;

    :hover {
      color: $admin-secondary-color;
    }
    :active {
      color: $admin-grey-1;
    }
  }
}