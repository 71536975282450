@import "~@/styles/themes.scss";
@import './../../styles/themes.scss';

.drawerContainer {
  margin-top: 30px;
  height: 100%;

  .drawerSubContainer {
    width: 450px;
    height: 100%;
    border-radius: 10px;
    box-shadow: $admin-main-shadow;
    padding: 20px;
    margin-top: 10px;
  }

  .modalBtnCancel {
    margin-top: -5%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    font-size: 18px;
    background-color: white;
    color: $admin-grey-3;
    border: none;

    &:hover {
      color: $admin-secondary-color;
    }

    &:active {
      color: $admin-grey-1;
    }
  }

  .modalBtnSave {
    margin-top: -5%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    font-size: 18px;
    background-color: white;
    color: $admin-accent-blue;
    border: none;
    &:hover {
      color: $admin-secondary-color;
    }

    &:active {
      color: $admin-grey-1;
    }
  }

  .sectionTitle {
    margin-bottom: 5px;

    font-family: $admin-title-font;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .description {
    margin-bottom: 7px;

    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    opacity: 0.7;
  }

  .defaultFont {
    margin-right: 70px;
  }

  .dyslexieFont {
    font-family: $dyslexie-font;
  }
}