@import "~@/styles/themes.scss";
.container {
  box-shadow: $admin-main-shadow;
  border-radius: 10px;
  padding: 20px 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  // width: 30vw;
}

.containerSelected {
  background-color: $admin-secondary-color;
  box-shadow: $admin-main-shadow;
  border-radius: 10px;
  padding: 20px 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  // width: 30vw;
}

.headerTitle {
  font-family: $primary-font;
  font-size: 12px;
  font-weight: 700;
  color: #0a0b09;
}

.title {
  font-family: $primary-font;
  font-size: 14px;
  color: #0a0b09;
  margin-left: 5px;
}

.deleteIcon {
  position: absolute;
  right: 40px;
  top: 40%;
  color: #8C8C8C;
  cursor: pointer;
}

.rightArrowIcon {
  position: absolute;
  right: 10px;
  top: 43%;
  color: #8C8C8C;
  transform: rotate(270deg);
}
