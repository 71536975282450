@import "~@/styles/themes.scss";
.container {
}

.buttonTitle {
  margin-left: 10px;
  font-size: 18px;
  color: #0a0b09;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #dadada;
  border-radius: 10px;
  padding: 12px;
  width: 314px;
  background-color: #ffffff;
}

.buttonContainerSelected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #1890ff;
  border-radius: 10px;
  padding: 12px;
  width: 314px;
  background-color: #ffffff;
}

.optionContainer {
  position: absolute;
  margin-top: 5px;
  padding: 12px;
  width: 314px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.1),
    0 3px 5px 0 rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.rowContainer {
  // padding-top: 4px;
  // padding-bottom: 4px;
  height: 55px;
  display: flex;
  // flex-direction: column;
  align-items: center;
}

.divider {
  // margin-top: 15px;
  height: 2px;
  width: 100%;
  background-color: #eaeaea;
}

.rowTitle {
  margin-left: 10px;
  font: $primary-font;
  font-size: 18px;
  color: #0a0b09;
}

.rowTitleSelected {
  margin-left: 10px;
  font: $primary-font;
  font-size: 18px;
  color: #1890ff;
}
