@import "~@/styles/themes.scss";
@import '../../styles/themes.scss';

.inputLabel {
  font-weight: 600;
  font-family: $secondary-font;
  margin-bottom: 5px;
}

.searchInput {
  border-radius: 10px;
  height: 60px;
  font-size: 18px;
  width: 100%;

  :global {
    .ant-input {
      font-size: 18px !important;
    }
  }

  &:focus {
    border: 2px solid $admin-secondary-color !important;
    box-shadow: none;
    border-color: $admin-secondary-color;
  }

  &:hover {
    box-shadow: none;
    border: 2px solid $admin-primary-color !important;
  }
}

.autoCompleteDropdown {
  width: 100%;
  z-index: 1000;
  position: absolute;
  padding: 5px;

  .suggestionItemActive, .suggestionItem {
    font-size: 18px;
    padding: 5px;
  }
}