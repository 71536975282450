@import "~@/styles/themes.scss";
@import '../../styles/themes.scss';

.title{
  font-family: $admin-title-font;
    font-weight: 700;
    font-size: 18px;
    // margin-top: 30px;
    margin-bottom: 30px;
    height: 70px;
    display: flex;
    background: none;
    padding: 0;
}