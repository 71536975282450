@import "~@/styles/themes.scss";
@import '../../styles/themes.scss';

.sider {
  flex: 0 0 70px !important;
  max-width: 70px !important;
  min-width: 70px !important;
  width: 70px !important;
  margin-right: 30px;
  background: none;
}

.siderContent {
  text-align: center;
  width: 70px;
  border-radius: 10px;
  margin-right: 30px;
  box-shadow: $admin-main-shadow;
}

.menuList  {

  i::before {
    margin-left: 0px !important;
  }
}

.iconContainer {
  padding: 8px;
}

.menuBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  width: 54px;
  border-radius: 4px;

  &:hover {
    color: #FFFFFF;
    background-color: $admin-primary-color;
    cursor: pointer;
  }
}

.menuBtnActive {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  width: 54px;
  border-radius: 4px;
  // color: #FFFFFF;
  background-color: $admin-secondary-color;
}