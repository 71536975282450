@import "~@/styles/themes.scss";
.carouselContainer{
  // opacity: 0.4;

  :global{
    .ant-carousel .slick-slider {
      // background: yellow;
      display: flex;
    }

    .ant-carousel .slick-slider .slick-track, .ant-carousel .slick-slider .slick-list {
      display: flex;
      align-items: center;
      // background-color: orange;
    }
    
  }
}


.carousel {
  // background: #FCEA25;
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  // margin-top: 20%;
  box-shadow: $admin-main-shadow;

  :global{
    
  }
}

.carousel img {
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.carousel video {
  display: block;
  height: 10%;
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.carousel ul li {
  background: #00000046;
  bottom: -35px;
}