@font-face {
  font-family: 'aposto';
  src:  url('/fonts/aposto.eot?2qedu2');
  src:  url('/fonts/aposto.eot?2qedu2#iefix') format('embedded-opentype'),
    url('/fonts/aposto.ttf?2qedu2') format('truetype'),
    url('/fonts/aposto.woff?2qedu2') format('woff'),
    url('/fonts/aposto.svg?2qedu2#aposto') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'aposto' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-archive:before {
  content: "\e955";
}
.icon-assignment:before {
  content: "\e952";
}
.icon-document:before {
  content: "\e94b";
}
.icon-three-o-clock-clock:before {
  content: "\e94a";
}
.icon-home:before {
  content: "\e900";
}
.icon-calendar:before {
  content: "\e901";
}
.icon-bell:before {
  content: "\e902";
}
.icon-hand:before {
  content: "\e903";
}
.icon-electric-guitar:before {
  content: "\e904";
}
.icon-microphone:before {
  content: "\e905";
}
.icon-football:before {
  content: "\e906";
}
.icon-mask:before {
  content: "\e907";
}
.icon-masks:before {
  content: "\e908";
}
.icon-chat-bubble:before {
  content: "\e909";
}
.icon-chat:before {
  content: "\e90a";
}
.icon-healthcare-and-medical:before {
  content: "\e90b";
}
.icon-megaphone:before {
  content: "\e90c";
}
.icon-bell-1:before {
  content: "\e90d";
}
.icon-search:before {
  content: "\e90e";
}
.icon-search-1:before {
  content: "\e90f";
}
.icon-search-2:before {
  content: "\e910";
}
.icon-star:before {
  content: "\e911";
}
.icon-award:before {
  content: "\e912";
}
.icon-cocktail:before {
  content: "\e913";
}
.icon-boxing:before {
  content: "\e914";
}
.icon-close:before {
  content: "\e915";
}
.icon-32178:before {
  content: "\e916";
}
.icon-cancel:before {
  content: "\e917";
}
.icon-qa:before {
  content: "\e918";
}
.icon-play:before {
  content: "\e919";
}
.icon-zoom:before {
  content: "\e91a";
}
.icon-refresh:before {
  content: "\e91b";
}
.icon-podium:before {
  content: "\e91c";
}
.icon-favourite:before {
  content: "\e91d";
}
.icon-2651320:before {
  content: "\e91e";
}
.icon-pause:before {
  content: "\e91f";
}
.icon-email:before {
  content: "\e920";
}
.icon-phone:before {
  content: "\e921";
}
.icon-id:before {
  content: "\e922";
}
.icon-camera:before {
  content: "\e923";
}
.icon-send:before {
  content: "\e924";
}
.icon-delete:before {
  content: "\e925";
}
.icon-trash:before {
  content: "\e926";
}
.icon-flash:before {
  content: "\e927";
}
.icon-traffic-sign:before {
  content: "\e928";
}
.icon-unlock:before {
  content: "\e929";
}
.icon-hourglass:before {
  content: "\e92a";
}
.icon-filter:before {
  content: "\e92b";
}
.icon-info:before {
  content: "\e92c";
}
.icon-bullet-list:before {
  content: "\e92d";
}
.icon-number:before {
  content: "\e92e";
}
.icon-link:before {
  content: "\e92f";
}
.icon-bold:before {
  content: "\e930";
}
.icon-italic:before {
  content: "\e931";
}
.icon-underline:before {
  content: "\e932";
}
.icon-landscape:before {
  content: "\e933";
}
.icon-user-interface:before {
  content: "\e934";
}
.icon-sort-arrows-couple-pointing-up-and-down:before {
  content: "\e935";
}
.icon-sort:before {
  content: "\e936";
}
.icon-forward:before {
  content: "\e937";
}
.icon-text-size:before {
  content: "\e938";
}
.icon-null:before {
  content: "\e939";
}
.icon-place:before {
  content: "\e93a";
}
.icon-map:before {
  content: "\e93b";
}
.icon-fullscreen:before {
  content: "\e93c";
}
.icon-fullscreen-1:before {
  content: "\e93d";
}
.icon-layer:before {
  content: "\e93e";
}
.icon-layer-1:before {
  content: "\e93f";
}
.icon-opinion:before {
  content: "\e940";
}
.icon-copy:before {
  content: "\e941";
}
.icon-sync:before {
  content: "\e942";
}
.icon-save:before {
  content: "\e943";
}
.icon-lock:before {
  content: "\e944";
}
.icon-avatar:before {
  content: "\e945";
}
.icon-speedometer:before {
  content: "\e946";
}
.icon-plus:before {
  content: "\e947";
}
.icon-down-arrow:before {
  content: "\e948";
}
.icon-tick:before {
  content: "\e949";
}
.icon-past:before {
  content: "\e94c";
  color: #1890ff;
}
.icon-withdraw:before {
  content: "\e94d";
  color: #1890ff;
}
.icon-menu:before {
  content: "\e94e";
}
.icon-draw:before {
  content: "\e94f";
}
.icon-global:before {
  content: "\e950";
}
.icon-avatar-2:before {
  content: "\e951";
}
.icon-EventRSVP:before {
  content: "\e953";
}
.icon-compass:before {
  content: "\e954";
}
.icon-download3:before {
  content: "\e9c7";
}
.icon-upload3:before {
  content: "\e9c8";
}
