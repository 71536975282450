@import "~@/styles/themes.scss";
.container {
  padding: 0 25px 60px 25px;
}

.errorText {
  display: flex;
  margin-top: 40%;
  justify-content: center;
  font-size: 16px;
  padding-left: 15%;
  padding-right: 15%;
  text-align: center;
}

.maincard {
  padding: 20px;
  border-radius: 20px;
  margin: 50px 0px 20px 20px;

  .imagecont {
    // background-color: indigo;
    //position: absolute;
    border-radius: 15px;
    overflow: hidden;
    margin: -40px 10px 10px -40px;
    height: 70px;
    width: 70px;

    img {
      height: 70px;
      width: 70px;
      object-fit: cover;
    }
  }

  .newtagcont {
    position: absolute;
    right: 10px;
    margin-top: -25px;
  }

  .titleTop {
    margin-top: -50px;
    margin-left: 40px;
  }
  .eventTitle {
    font-size: 14px;
    color: $pwa-grey-1;
  }

  .location {
    font-size: 12px;
    font-weight: bold;
    color: $pwa-grey-3;
  }

  .imagesContainer {
    width: 100%;
    // background-color: indigo;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .time {
    font-weight: bold;
  }

  .paragraph {
    opacity: 0.6;
    white-space: pre-wrap;
  }
}

.shadow {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.1),
    0 3px 5px 0 rgba(0, 0, 0, 0.1);
}