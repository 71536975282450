@import "~@/styles/themes.scss";
@import '../../styles/themes.scss';

.eventCard {
  // width: 357px;
  height: 212px;
  border-radius: 10px;
  box-shadow: $admin-main-shadow;
  overflow: hidden;
}

.contentLeft {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.matchTimeStamp {
  font-size: 12px;
  font-weight: 700;
}

.matchDetails {
  display: flex;
  align-items: center;
}

.matchIcon {
  height: 24px;
  width: 24px;
  margin-right: 12px;

  i::before {
    color: $admin-secondary-color;
    font-size: 22px;
    margin-left: 0px !important;
  }
}

.matchName {
  width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.matchLocation {
  font-weight: 700;
  font-size: 12px;
  color: $admin-grey-3;
  width: 170px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.imageContainer {
  margin: auto;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 10px;
    object-fit: contain;
    height: 98px;
  }
}

.contentRight {
  background-color: $admin-grey-7;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.staffRecruit {
  display: flex;
  flex-direction: column;
  margin-bottom: 23px;
}

.recruitTitle {
  text-transform: uppercase;
  font-family: $admin-title-font;
  font-weight: 600;
}

@media screen and (max-width: 390px) {
  .imageContainer {
    img {
      height: 80px;
    }
  }

  .contentRight {
    background-color: $admin-grey-7;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: -1rem;
    padding-top: 15px;
    padding-bottom: 1.2rem;
  }
}

.emptyCardText {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
