@import "~@/styles/themes.scss";
@import '../../../styles/themes.scss';

.container {
  padding: 30px;
}

.eventImage {
  border-radius: 10px;
}

.title {
  color: $pwa-grey-1;
  font-size: 24px;
  font-weight: bold;
  font-family: $secondary-font;
}

.dangerColor{
  color: red;
}


.subtitle {
  color: $pwa-grey-1;
  font-size: 18px;
  font-weight: bold;
  font-family: $secondary-font;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.eventContainer {
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 25px;
  background-color: #fff;

  .time {
    font-family: $secondary-font;
    font-size: 12px;
    font-weight: bold;
    color: $pwa-grey-1;
    margin-bottom: 10px;
  }

  .eventTitle {
    font-size: 16px;
    color: $pwa-grey-1;
  }

  .location {
    font-size: 12px;
    font-weight: bold;
    color: $pwa-grey-3;
  }
  .upSubContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .subContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon {
    color: $pwa-accent-blue;
    margin-right: 10px;
  }

  .newtagcont {
    position: absolute;
    right: 15px;
    margin-top: -20px;
  }
}

.generalContainer {
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  margin-bottom: 25px;
  background-color: $pwa-primary-color;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.location {
  font-size: 12px;
  font-weight: bold;
  color: $pwa-grey-3;
}

.comingUpContainer {
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 75px;

  .time {
    font-family: $secondary-font;
    font-size: 12px;
    font-weight: bold;
    color: $pwa-grey-1;
    margin-bottom: 10px;
  }

  .eventTitle {
    font-size: 16px;
    color: $pwa-grey-1;
  }

  .location {
    font-size: 12px;
    font-weight: bold;
    color: $pwa-grey-3;
  }

  .subContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon {
    color: $pwa-accent-blue;
    margin-right: 10px;
  }
}

.comingUpBottomContainer {
  display: flex;
  position: absolute;
  padding: 20px;
  width: 90%;
  background-color: white;
  left: 5%;
  bottom: -40px;
  border-radius: 15px;
  justify-content: space-evenly;
}

.comingUpBottomContainer {
  display: flex;
  position: absolute;
  padding: 5px;
  width: 90%;
  background-color: white;
  left: 5%;
  bottom: -50px;
  border-radius: 15px;
  justify-content: space-evenly;
}

.comingUpBottomContainer_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100vh;

  .comingContainerTitle {
    font-size: 10px;
    font-weight: 800;
  }

  .comingIconContainer {
    display: flex;
    padding: 10px;
    background-color: $pwa-grey-7;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    margin-left: 2px;
    margin-right: 2px;

    &:active {
      background-color: $pwa-secondary-color;
    }
  }

  .comingIcon {
    color: $pwa-grey-2;

    &:active {
      color: white;
    }
  }
}

.shadow {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.1),
    0 3px 5px 0 rgba(0, 0, 0, 0.1);
}

.placeholderText {
  margin-top: 60px;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
}

.placeholderSubText {
  margin-top: 30px;
  font-size: 16px;
  text-align: center;
}

.topspace {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: palevioletred;
  margin-bottom: 30px;
}

.newtag {
  background-color: $pwa-accent-red;
  padding: 0 5px 0 5px;
  margin: 0 10px 0 10px;
  font-weight: 900;
  border-radius: 5px;
  font-size: 10px;
  display: flex;
  align-items: center;
  font-family: $primary-font;
  color: #fff;
  height: 20px;
  max-width: 40px;
}

.notiDetailContainer {
  // margin: -48px;
  height: 100%;
  width: 100%;
  margin: -24px;
  overflow-y: auto;
  position: fixed;

  .topContainer {
    // margin: -24px -24px 20px -24px;
    display: flex;
    align-items: center;
    padding: 24px 24px 12px 24px;
    background-color: $pwa-primary-color;
    position: relative;

    .time {
      color: white;
      font-size: 12px;
      font-weight: bold;
    }

    .title {
      color: white;
      font-size: 16px;
    }

    .backIcon {
      color: white;
      font-size: 24px;
      margin-right: 15px;
    }

    .announcementIcon {
      position: absolute;
      right: 24px;
      color: white;
    }
  }

  .maincard {
    padding: 20px;
    border-radius: 20px;
    margin: 50px 0px 20px 20px;

    .imagecont {
      // background-color: indigo;
      //position: absolute;
      border-radius: 15px;
      overflow: hidden;
      margin: -40px 10px 10px -40px;
      height: 70px;
      width: 70px;

      img {
        height: 70px;
        width: 70px;
        object-fit: cover;
      }
    }

    .newtagcont {
      position: absolute;
      right: 10px;
      margin-top: -25px;
    }

    .titleTop {
      margin-top: -50px;
      margin-left: 40px;
    }
    .eventTitle {
      font-size: 14px;
      color: $pwa-grey-1;
    }

    .location {
      font-size: 12px;
      font-weight: bold;
      color: $pwa-grey-3;
    }

    .imagesContainer {
      width: 100%;
      // background-color: indigo;

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .time {
      font-weight: bold;
    }

    .paragraph {
      opacity: 0.6;
      white-space: pre-wrap;
    }
  }
}

.notiFeedbackContainer {
  // margin: -48px;
  // background: lightpink;
  position: absolute;
  top: 20px;
  margin: -24px;
  min-height: 100vh;
  padding: 24px;
  width: 100%;

  .subnet {
    // background: lightpink;
  }

  .bottombuttons {
    color: #fff;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    // width: 100%;

    :global {
      .ant-upload-list {
        max-width: 70vw;
      }
    }

    .addphotobutton {
      background-color: #000;
      width: 100%;
      // min-width: calc('100vw'-'80px');
      // min-width: 70vw;
      max-width: 70vw; // calc(~"100vw - 70px");
      padding: 10px 30px;
      height: 60px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      color: #fff;
    }

    .sendbutton {
      background-color: $pwa-secondary-color;
      min-width: 60px !important;
      height: 60px;
      // padding: 10px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .finishMessage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
  }
}

.feedbackBackButton {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 9999;
  border-radius: 20px;
  height: 40px;
  width: 40px;
  border: solid 2px red;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.notiFbBg {
  position: absolute;
  left: 0px;
  top: 0px;

  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 100%
    ),
    url('/images/dummy/pwa-login-bg.png');
  height: 20vh;
  width: 100vw;
}

.ratingContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  // max-width: 350px;
}

.textarea {
  margin: 20px 0px 20px 0px;
  border-radius: 15px;
  padding: 10px;
}

.carouselContainer {
  width: 100%;
  margin-bottom: 40px;
  margin-top: 20px;

  .imageContainer {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .carouselImage {
      object-fit: cover;
      // background-color: yellow;
    }

    .carouselVideo {
      // object-fit: cover;
      // position: absolute;
      // right: 0;
      // bottom: 0;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      background-size: cover;
      overflow: hidden;
    }
  }

  .videoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 90px;

    .carouselVideo {
      min-width: 100%;
    }
  }

  :global {
    .ant-carousel .slick-dots {
      // background-color: indigo;
    }
    .ant-carousel .slick-dots-bottom {
      bottom: -30px;
      height: 12px;
      // background-color: indigo;
      // align-items: center;
      // display: flex;
    }

    .ant-carousel .slick-dots li button {
      width: 10px;
      height: 10px !important;
      border-radius: 5px;
      background-color: $pwa-grey-1;
    }

    .ant-carousel .slick-dots li.slick-active button {
      background-color: $pwa-secondary-color;
      width: 12px;
      height: 12px !important;
      margin-top: -1px !important;
      border-radius: 6px;
    }

    .ant-carousel .slick-slider {
      // background: yellow;
      display: flex;
    }

    .ant-carousel .slick-slider .slick-track,
    .ant-carousel .slick-slider .slick-list {
      display: flex;
      align-items: center;
      // background-color: orange;
    }
  }
}

.mediaCarouselContainer {
  :global {
    .ant-carousel .slick-slider {
      box-shadow: none;
      -webkit-box-shadow: none;
      border: 1px solid lightgray;
    }
  }
}

.feedbackButton {
  font-weight: 600;

  margin: 15px 0 0 0;
  padding: 0;
  height: min-content;
  line-height: normal;

  display: flex;
  align-items: center;
}
