@import "~@/styles/themes.scss";
.pointerCursor {
  cursor: pointer;
}

.progressContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: $admin-main-shadow;
  border-radius: 15px;
  height: 200px;
  width: 100%;
  //color: green;
}

.progressCircle {
  background-color: white;
  left: 0px;

  :global {
    .ant-progress-text {
      font-size: 30px;
      font-weight: bold;
      // opacity: 0.4;
    }
  }
}

.addOnProgreeCircle {
  // background-color: green;
  :global {
    .ant-progress-circle {
      opacity: 0;
    }
  }
}

.numberPercentage {
  font-family: $primary-font;
  font-size: 2rem;
  font-weight: 800;
}
.percentage {
  font-size: 20px;
  display: inline;
}

.title {
  line-height: 1.5em;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  color: #1b1b1b;
  font-family: $secondary-font;
  font-weight: bold;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}
