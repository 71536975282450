@import "~@/styles/themes.scss";
@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Muli:400,500,600,700&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Muli', sans-serif;
}

.ant-layout {
  background-color: #ffffff;
}


.default-layout-container {
  margin: auto;
  padding: 20px;
  max-width: 1600px;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

// Antd custom
.ant-carousel .slick-dots li.slick-active button {
  background: $pwa-secondary-color;
}


// @media (min-width: 1920px) {
//   .default-layout-container {
//     padding: 0 260px;
//   }
// }

// @media (max-width: 1200px) {
//   .default-layout-container {
//     padding: 0;
//   }
// }

// DYSLEXIE SETUP
@font-face {
  font-family: 'Dyslexie';
  src: url('../../public/fonts/OpenDyslexic3-Regular.ttf');
}

// if user object has "hasDyslexia" property this class is added to body
// select all children of body
.dyslexieFont * {
  font-family: 'Dyslexie' !important;
}