@import "~@/styles/themes.scss";
@import '../../styles/themes.scss';

.container {
  box-shadow: $admin-main-shadow;
  border-radius: 10px;
}
.checkBoxSms{
  border: 2px #dadada solid;
  padding: 20px 20px 20px 15px;
  width: 100%;
  border-radius: 10px;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}

.selectOptions{
  border: 2px #dadada solid;
  border-radius: 10px;
  font-family: "Raleway";
  font-size: 14px;
}

.mainContainer {
  box-shadow: $admin-main-shadow;
  border-radius: 10px;
  padding: 20px 15px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;

  .mainTitle {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 900;
    color: $admin-grey-1;
  }
  .matchIcon {
    height: 24px;
    width: 24px;
    margin-right: 12px;

    i::before {
      color: $admin-secondary-color;
      font-size: 22px;
      margin-left: 0px !important;
    }
  }

  .matchTimeStamp {
    font-size: 12px;
    font-weight: 700;
    color: $admin-grey-1;
  }
  .matchName {
    width: 80%;
    overflow-wrap: anywhere;
  }

  .matchLocation {
    font-weight: 700;
    font-size: 12px;
    color: $admin-grey-3;
  }
}

.mainContainerSelected {
  box-shadow: $admin-main-shadow;
  border-radius: 10px;
  padding: 20px 15px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  background-color: $admin-secondary-color;

  .mainTitle {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 700;
  }
  .matchIcon {
    height: 24px;
    width: 24px;
    margin-right: 12px;

    i::before {
      font-size: 22px;
      margin-left: 0px !important;
    }
  }

  .matchTimeStamp {
    font-size: 12px;
    font-weight: 700;
  }

  .matchName {
    width: 80%;
    overflow-wrap: anywhere;
  }

  .matchLocation {
    font-weight: 700;
    font-size: 12px;
  }
}

.notificationsContainer {
  box-shadow: $admin-main-shadow;
  // background-color: red;
  border-radius: 10px;
  padding: 20px 15px 20px;
  display: flex;
  margin-bottom: 20px;
  align-items: center;

  .textContainer {
    margin-left: 10px;
    .date {
      font-family: $primary-font;
      font-size: 10px;
      font-weight: 500;
      color: $admin-grey-3;
    }

    .name {
      font-family: $primary-font;
      font-size: 14px;
      font-weight: 700;
      color: $admin-grey-1;
    }

    .title {
      font-family: $primary-font;
      font-size: 14px;
      font-weight: 500;
      color: $admin-grey-2;
    }
  }

  .mainTitle {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 700;
  }

  .image {
    height: 40px;
    width: 40px;
  }
}

.notificationsContainerSelected {
  box-shadow: $admin-main-shadow;
  // background-color: red;
  border-radius: 10px;
  padding: 20px 15px 20px;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  background-color: $admin-secondary-color;

  .textContainer {
    margin-left: 10px;
    .date {
      font-family: $primary-font;
      font-size: 10px;
      font-weight: 500;
      // color: white;
    }

    .title {
      font-family: $primary-font;
      font-size: 14px;
      font-weight: 700;
      // color: white;
    }
  }

  .mainTitle {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 700;
  }

  .image {
    height: 40px;
    width: 40px;
    // background-color: white;
    border-radius: 10px;
  }
}

.contentContainer {
  box-shadow: $admin-main-shadow;
  // background-color: red;
  border-radius: 10px;
  padding: 20px 15px 20px;
  min-height: 100vh;

  .announcementsCarousel {
    // background-color: chocolate;
  }

  .date {
    font-family: $primary-font;
    font-size: 14px;
    font-weight: 700;
    color: $admin-grey-1;
    margin-bottom: 8px;
  }
  .imageContainer {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: chartreuse;

    .carouselImage {
      object-fit: cover;
      max-height: 500px;
      // background-color: yellow;
    }

    .carouselVideo {
      // object-fit: cover;
      // position: absolute;
      // right: 0;
      // bottom: 0;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      background-size: cover;
      overflow: hidden;
    }
  }

  .videoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 90px;

    .carouselVideo {
      min-width: 100%;
    }
  }

  .content {
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: 500;
    color: $admin-grey-2;
    white-space: pre-wrap;
  }
}

.addNotificationButton {
  background-color: blue;
}

.addButton {
  color: white;
  background-color: #1890ff;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  align-items: center;
  width: 170px;
  height: 60px;
  justify-content: space-between;
  // padding: 16px;

  .title {
    font-family: $secondary-font;
    font-size: 18px;
    font-weight: 700;
  }

  &:focus {
    background: #1890ff;
  }
}

.searchBar {
  height: 60px;
  border-radius: 10px;
}

.modalTitle {
  margin-bottom: 8px;
  font-family: $secondary-font;
  font-size: 14px;
  font-weight: 600;
  color: $admin-grey-1;
}

.modalContent {
  border: solid 2px $admin-grey-5;
  padding: 15px;
  border-radius: 5px;

  .text {
    font-family: $secondary-font;
    font-size: 18px;
    font-weight: 500;
    color: $admin-grey-2;
  }
}

.matchDetails {
  display: flex;
  align-items: center;
}

.avatar {
  border-radius: 10px;
}

.uploadGallery {
  :global {
    .ant-upload-list-picture-card .ant-upload-list-item {
      border-radius: 10px !important;
      padding: 0;
    }
    .ant-upload-list-picture-card-container {
      height: 87px !important;
      width: 87px !important;
    }
    .ant-upload-list-item-info {
      border-radius: 10px;
    }
    .ant-upload-list-item-thumbnail img {
      object-fit: cover !important;
    }
    .ant-upload-select.ant-upload-select-picture-card {
      height: 87px !important;
      width: 87px !important;
      border: 2px dashed $admin-grey-3;
      border-radius: 10px;
    }
  }
}

.DepartmentsTitle {
  font-weight: 700;
}
