@import "~@/styles/themes.scss";
.container {
  padding: 6px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.containerDrawer {
  margin: -24px;
}

.topContainerDrawer {
  display: flex;
  align-items: center;
  padding: 24px;
  background-color: #f3921e;
  position: relative;

  .progressText {
    // color: white;
    font-size: 12px;
    font-weight: 600;
  }

  .title {
    // color: white;
    font-size: 16px;
  }
}

.topContainer {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: $pwa-primary-color;
  position: relative;
}

.shadow {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.1),
    0 3px 5px 0 rgba(0, 0, 0, 0.1);
}

.topContainer {
  border-radius: 9px;
  background: linear-gradient(
    206.57deg,
    rgba(255, 255, 255, 0.29) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background-color: #0A0B09;
  // padding: 12px;
  // margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftIcon {
  color: $pwa-secondary-color;
}

.bottomContainer {
  margin-top: 6px;
  border-radius: 9px;
  background-color: $pwa-grey-7;
  padding: 12px;
  display: flex;
  flex-direction: column;
}

.titleText {
  font-family: $secondary-font;
  font-size: 14px;
  font-weight: 600;
  color: $pwa-secondary-color;
  margin-left: 10px;
}

.statusText {
  font-size: 14px;
  font-weight: 800;
  color: #070d22;
}
.progressText {
  // color: white;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  bottom: 10%;
}
.scoreText {
  font-size: 10px;
  font-weight: 800;
  color: #a1a1a1;
  margin-top: 10px;
  margin-right: 7px;
}

.percentageText {
  font-size: 27px;
  font-weight: 800;
  color: #070d22;
}

.percentageSymbol {
  color: #070d22;
  font-size: 18px;
  font-weight: 800;
  margin-top: 10px;
  margin-left: 3px;
}

.greyPercentageBar {
  height: 9px;
  width: 100%;
  border-radius: 4.5px;
  background-color: #dadada;
}

.percentageBar {
  position: absolute;
  height: 9px;
  border-radius: 4.5px;
  z-index: 100;
  bottom: 0;
}

.rightIcon {
  color: #8c8c8c;
  margin-left: 7.5px;
  margin-top: 8px;
}

.rightIconWhite {
  color: $pwa-secondary-color;
}

.takeQuizText {
  color: $pwa-grey-1;
  font-family: $secondary-font;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
}

.topicTitle {
  color: $pwa-grey-1;
  font-size: 24px;
  font-weight: bold;
  font-family: $secondary-font;
  margin-bottom: 10px;
  padding: 10px;
  padding-left: 20px;
}

.topicContent {
  text-align: left;
  padding: 10px;
  padding-left: 20px;
}

.topicBtn {
  height: 70px;
  width: 70px;
  border-radius: 15px;
  background-color: #f3921e;
}

.topicBtn:hover {
  height: 70px;
  width: 70px;
  border-radius: 15px;
  background-color: #f3921e;
}

.arrow {
  color: white;
}

.quizLandingDrawer .ant-drawer-body {
  background-color: #f3921e;
}
