@import "~@/styles/themes.scss";
@import '../../styles/themes.scss';

.container {
  margin-top: 20px;

  
}
.scheduleDate{
  font-size: 16px;
  font-weight: 600;
  font-family: $admin-title-font;
  color: $admin-primary-color;
  margin: 20px;
}

.circle {
  background-color: $admin-primary-color;
  width: 11px;
  height: 11px;
  border-radius: 5.5px;
}

.verticalLine {
  height: 100%;
  width: 1px;
  background-color: $admin-grey-6;
  margin-left: 5px;
}

.descriptionContainer {
  margin-left: 15px;
  margin-top: -5px;
  margin-bottom: 15px;
}

.time {
  font-family: $secondary-font;
  font-size: 14px;
  font-weight: bold;
  color: $admin-grey-1;
}

.title {
  font-size: 16px;
  color: $admin-grey-2;
}

.notes {
  font-size: 14px;
  color: $admin-status-red;
  margin-top: 5px;
}

.notesContainer {
  display: flex;
  align-items: center;
}

.notesIcon {
  margin-top: 6px;
  color: $admin-status-red;
  margin-right: 5px;
}
