@import "~@/styles/themes.scss";
@import '../../styles/themes.scss';

.headerContainer {
    font-family: $admin-title-font;
    font-weight: 700;
    font-size: 18px;
    // margin-top: 30px;
    margin-bottom: 30px;
    height: 70px;
    display: flex;
    background: none;
    padding: 0;
    margin-top: -1.5rem;
}

.headerLogo {
    width: 70px;
    margin-right: 30px;
    border-radius: 10px;
    background-color: $admin-primary-color;
    
}

.backBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 100%;
  background-color: $admin-accent-blue;
  cursor: pointer;

  i {
    color: #FFFFFF;
  }
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  object-fit: contain;
}

.logo {
  object-fit: contain;
}

.headerContent {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    line-height: 1;
    background-color: $admin-primary-color;
    color: #FFFFFF;
    padding-right: 25px;
    padding-left: 25px;
}

.headerRight {
  display:flex;

  img {
    height: 40px;
    width: 40px;
    object-fit: contain;
}
}

.logoutBtn {
  font-size: 14px;
  font-weight: 600;
  margin-right: 25px;
  cursor: pointer;
  margin-top: 0.8rem;
}



.popverContainer{
  margin: -5px;
  // background-color: indigo;
}
.popoverbutton {
  width: 160px;
  background-color: $admin-grey-7;
  margin:7px 0px;
  padding: 5px 12px;
  font-family: $secondary-font;
  font-weight: 600;
  border-radius: 3px;

  cursor: pointer;
}

.emptyAvatar {
  background-color: gray;
  height: 42px;
  width: 42px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
}
