@import "~@/styles/themes.scss";
.newItemModal {
  .modalInputContainer {
    padding: 40px 30px;
  }

  .modalBtnContainer {
    display: flex;

    .modalBtnCancel {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      font-size: 18px;
      height: 70px;
      background-color: $admin-grey-7;
      color: $admin-grey-3;
      border-bottom-left-radius: 30px;

      &:hover {
        // color: $admin-secondary-color;
      }

      &:active {
        color: $admin-grey-1;
      }
    }
    .modalBtnSave {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      font-size: 18px;
      height: 70px;
      background-color: $admin-grey-7;
      color: $admin-accent-blue;
      border-bottom-right-radius: 30px;

      &:hover {
        // color: $admin-secondary-color;
      }

      &:active {
        color: $admin-grey-1;
      }
    }
  }
  :global {
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-content {
      border-radius: 30px !important;
    }
  }
  .actionButtonContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    .topBtnSave {
      background: $admin-accent-blue;
      color: white;
      margin-right: 10px;
      border-radius: 4px;
    }
    .topBtnCancel {
      border-radius: 4px;
      color: $admin-status-red;
      border: 1px solid $admin-status-red;
    }
  }
}

.title {
  font-family: $secondary-font;
  color: $pwa-grey-1;
  font-weight: 600;
  font-size: 14px;
}

.checkboxContainer {
  margin-top: 5px;
  display: flex;
}

.generalModalContainer {
  :global {
    .ant-modal-content {
      border-radius: 25px !important;
    }
  }
}

.csvTable {
  .errorRow {
    color: $admin-accent-red;
  }
}

.errorModalContent {
  padding: 30px;
  .errorModalTitle {
    font-size: 18px;
    padding-bottom: 10px;
  }
}
